import { GImage } from '../g-image';

interface GalleryImageTypes {
  image: string;
  userName: string;
  openLightbox: any;
  visibleByDefault: boolean;
}

function GalleryImage({
  image,
  userName,
  openLightbox,
  visibleByDefault,
}: GalleryImageTypes): JSX.Element {
  return (
    <div
      onClick={openLightbox}
      className="group relative w-36 h-36 md:w-full md:h-auto pt-full overflow-hidden"
    >
      <div className="absolute inset-0 cursor-pointer">
        <GImage
          path={image}
          alt={`social media post by ${userName ?? 'unknown user'}`}
          height="100%"
          width="100%"
          classes="object-cover object-center h-full w-full "
          hasLoadingBackground
          visibleByDefault={visibleByDefault}
        />
      </div>
      <div className="absolute bottom-2.5 left-2.5 text-light-600">
        <div className="flex items-center">
          <i className="icon-instagram mr-2.5"></i>
          <span className="hidden md:block">{userName}</span>
        </div>
      </div>
    </div>
  );
}

function GalleryImagePlaceholder(): JSX.Element {
  return (
    <div className="w-full h-auto pt-full bg-light-900 animate-pulse"></div>
  );
}

export { GalleryImage, GalleryImagePlaceholder };

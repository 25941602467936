export const BannerTransformations = {
  SM: 'banner-sm',
  MD: 'banner-md',
  LG: 'banner-lg',
};

export const BannerAutoGravityTransformations = {
  SM: 'banner-sm-auto-gravity',
  MD: 'banner-md-auto-gravity',
  LG: 'banner-lg-auto-gravity',
};

export const CareersTransformations = {
  SM: 'careers-sm',
  MD: 'careers-md',
  LG: 'careers-lg',
};

export const SliderTransformations = {
  SM: 'offset-slider-large-sm',
  MD: 'offset-slider-large-md',
  LG: 'offset-slider-large-lg',
};

export const ProductImageTransformations = {
  SM: 'g-product-banner-sm',
  MD: 'g-product-banner-md',
  LG: 'g-product-banner-lg',
};

export const ProductGalleryTransformations = {
  SM: 'gallery-sm',
  MD: 'gallery-md',
  LG: 'gallery-lg',
};

export const LightBoxTransformations = {
  SM: 'small',
  MD: 'medium',
  LG: 'large',
};
export type TTransformation = {
  SM: string;
  MD: string;
  LG: string;
};

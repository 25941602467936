import { GalleryImage, GalleryImagePlaceholder } from '../gallery-image';

import Head from 'next/head';
import LightBox from '../lightbox';
import { galleryData } from '@/Lib/types/gallery';
import { useState } from 'react';

interface GalleryTypes {
  tags: string[];
  min: number;
  hasPadding?: boolean;
  isInProductPage?: boolean;
  isInReviewPage?: boolean;
  isLinkLightBox?: boolean;
  data: galleryData;
  preloadImages?: boolean;
}

function Gallery({
  tags,
  min,
  hasPadding = true,
  isInProductPage = false,
  isInReviewPage = false,
  isLinkLightBox = true,
  data,
  preloadImages = false,
}: GalleryTypes): JSX.Element {
  let galleryImages = data ? data?.socialMedia.slice(0, min) : [];
  // if (isInProductPage) {
  //   galleryImages = data ? data?.pixlee.slice(0, 6) : [];
  // }

  const [index, setIndex] = useState(-1);
  const [showLightBox, setShowLightBox] = useState(false);
  const reset = () => {
    setIndex(-1);
    setShowLightBox(false);
  };
  const openLightBox = (index: number) => {
    setIndex(index);
    setShowLightBox(true);
  };

  return (
    <>
      {preloadImages ? (
        <Head>
          {galleryImages.map((item, index) => (
            <link
              key={index}
              rel="preload"
              href={item.image.medium}
              as="image"
            />
          ))}
        </Head>
      ) : null}
      <div
        className={
          'grid overflow-x-scroll overflow-y-hidden md:gap-1.5 pl-5 lg:overflow-visible hide-webkit-scrollbar ' +
          (hasPadding
            ? ' max-w-6.75xl mx-auto lg:px-10 lg:pb-0'
            : ' pb-2 lg:p-0') +
          (isInProductPage
            ? min === 6
              ? ' md:grid-cols-3-important lg:grid-cols-3-important lg:grid-rows-auto '
              : ' md:grid-cols-2-important lg:grid-cols-2-important lg:grid-rows-auto h-full '
            : ' ') +
          (isInReviewPage
            ? 'md:grid-cols-7-important'
            : 'md:grid-cols-5-important')
        }
        style={{
          gridTemplateColumns: `repeat(${galleryImages.length}, 9rem)`,
          scrollBehavior: 'smooth',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {galleryImages ? (
          <>
            {galleryImages.map((item, index) => (
              <GalleryImage
                key={index}
                image={item.image.medium}
                userName={item.userName as string}
                openLightbox={() => openLightBox(index)}
                visibleByDefault={preloadImages}
              />
            ))}

            <LightBox
              images={galleryImages}
              index={index}
              show={showLightBox}
              reset={reset}
              isLink={isLinkLightBox}
            />
          </>
        ) : null}
        {!data
          ? [...Array(min)].map((_, index) => (
              <GalleryImagePlaceholder key={index} />
            ))
          : null}
      </div>
    </>
  );
}

export { Gallery };

import { SocialMediaGallery } from '@/Lib/types/gallery';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { generateUrl, GImage } from '../g-image';
import Modal from 'react-modal';
import { useSwipeable } from 'react-swipeable';
import { AppLink } from '@/Components/link/link';
import {
  useTransformationBasedOnWindowSize,
  useTransformationBasedOnWindowSizeLightBox,
} from '@/Lib/hooks/useTransformationBasedOnWindowSize';
import { ProductGalleryTransformations } from '@/Lib/types/image-transformations';
import styles from './htmldesc.module.css';
import { useScroll } from '@/Lib/contexts/ScrollContext';

const customModalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: 'transparent',
  },
  content: {
    border: 'none',
    backgroundColor: 'transparent',
  },
};

type LightBoxProps = {
  images: SocialMediaGallery[];
  index: number;
  show: boolean;
  reset: any;
  isLink?: boolean;
  isWithDes?: boolean;
  useProductTransform?: boolean;
  isUseTransfprmation?: boolean;
  isDescHtml?: boolean;
  showImageNumbers?: boolean;
  isShowSide?: boolean;
  isPreload?: boolean;
};

const LightBox = ({
  images,
  index,
  show,
  reset,
  isLink = true,
  isWithDes = true,
  useProductTransform = false,
  isUseTransfprmation = true,
  isDescHtml = false,
  showImageNumbers,
  isShowSide = true,
  isPreload = false,
}: LightBoxProps) => {
  const transformation = useTransformationBasedOnWindowSizeLightBox();
  const Producttransformation = useTransformationBasedOnWindowSize(
    ProductGalleryTransformations
  );

  const ref = useRef<HTMLInputElement>(null);
  const leftRef = useRef<HTMLInputElement>(null);
  const rightRef = useRef<HTMLInputElement>(null);
  const mainRef = useRef<HTMLInputElement>(null);
  const [imageIndex, setImageIndex] = useState(index);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [size, setSize] = useState(transformation);
  const [load, setLoad] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const handleClickOutside = (e: any) => {
    if (
      mainRef?.current?.contains(e.target) &&
      !ref?.current?.contains(e.target) &&
      !leftRef?.current?.contains(e.target) &&
      !rightRef?.current?.contains(e.target)
    ) {
      setIsOpen(false);
      setLoad(false);
      reset();
    }
  };
  const resetLightbox = () => {
    setIsOpen(false);
    setLoad(false);
    reset();
  };
  const next = () => {
    if (!(imageIndex + 1 > images.length - 1)) {
      setImageIndex(imageIndex + 1);
      setLoad(false);
    }
  };
  const prev = () => {
    if (imageIndex > 0) {
      setImageIndex(imageIndex - 1);
      setLoad(false);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    const { keyCode } = event;
    if (keyCode === 39) rightRef?.current?.click();
    if (keyCode === 37) leftRef?.current?.click();
  };

  useEffect(() => {
    if (!window) return;
    // multiple instances of this comp may be used in a single page. so you
    // should only register handler if show is true (one instance is showing)
    if (!show) return window.removeEventListener('keyup', handleKeyPress);
    window.addEventListener('keyup', handleKeyPress);
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, [show]);

  // cache 2 images to the right
  useEffect(() => {
    const CACHE_OFFSET = 2;
    // don't go over the array length
    if (imageIndex + CACHE_OFFSET >= images.length) return;
    for (let i = 1; i <= CACHE_OFFSET; i++) {
      // make sure you skip an already cached image, given CACHE_OFFSET=2 only
      // the first time we need to cache 2 images, every step that user goes
      // forward we only need to add one more to the cache
      if (imageIndex !== 0) i++;
      const imageToCache = isWithDes
        ? images[imageIndex + i]?.image[size]
        : images[imageIndex + i]?.image;
      const img = new Image();
      img.src = generateUrl(
        imageToCache,
        undefined,
        undefined,
        useProductTransform ? Producttransformation : '',
        true
      );
    }
  }, [imageIndex, isWithDes]);

  const decodeTitle = (title: string) => {
    try {
      return decodeURIComponent(title);
    } catch (e) {
      return '';
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    setIsOpen(show);
    setImageIndex(index);
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, index]);
  const { disableScroll, enableScroll } = useScroll();
  useEffect(() => {
    if (isOpen) {
      disableScroll();
    }

    // Cleanup when the modal is closed or unmounted
    return () => {
      if (isOpen) {
        enableScroll();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    Modal.setAppElement('#__next');
  }, []);

  useEffect(() => {
    setSize(transformation);
  }, [transformation]);

  // cache 2 images to the right
  useEffect(() => {
    if (isPreload && images.length > 0) {
      const CACHE_OFFSET = 2;
      // don't go over the array length
      if (imageIndex + CACHE_OFFSET >= images.length) return;
      for (let i = 1; i <= CACHE_OFFSET; i++) {
        // make sure you skip an already cached image, given CACHE_OFFSET=2 only
        // the first time we need to cache 2 images, every step that user goes
        // forward we only need to add one more to the cache
        if (imageIndex !== 0) i++;
        const imageToCache = images[imageIndex + i]?.image;
        const img = new Image();
        img.src = generateUrl(
          imageToCache,
          undefined,
          undefined,
          Producttransformation,
          true
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIndex, isPreload]);

  return (
    <>
      <Modal isOpen={isOpen} style={customModalStyles} closeTimeoutMS={300}>
        <div
          ref={mainRef}
          className={
            'z-50 overflow-hidden fixed top-0 right-0 left-0 bottom-0 w-full h-full  flex justify-center items-center bg-lightback'
          }
        >
          <div
            className="absolute top-5 right-7 z-20 cursor-pointer"
            onClick={() => resetLightbox()}
          >
            <i className="icon-close text-lg text-light-800"></i>
          </div>
          <div
            className={
              'absolute box-border top-1/2 z-20 cursor-pointer flex lg:px-2.5rem justify-between w-full  text-light-800' +
              (isWithDes ? ' md:text-6xl text-2xl' : ' text-lg')
            }
          >
            <div
              ref={leftRef}
              onClick={prev}
              className={
                !isWithDes && imageIndex > 0
                  ? ' border border-light-600 w-3.125rem h-3.125rem rounded-full flex justify-center items-center'
                  : ''
              }
            >
              <i
                className={'icon-left px-1 ' + (!(imageIndex > 0) && 'hidden')}
              ></i>
            </div>
            <div
              ref={rightRef}
              onClick={next}
              className={
                !isWithDes && imageIndex < images.length - 1
                  ? ' border border-light-600 w-3.125rem h-3.125rem rounded-full flex justify-center items-center'
                  : ''
              }
            >
              <i
                className={
                  'icon-right px-1 ' +
                  (!(imageIndex < images.length - 1) && 'hidden')
                }
              ></i>
            </div>
          </div>
          <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="lightbox-scale"
            unmountOnExit
            onEnter={() => setIsOpen(true)}
            onExited={() => setIsOpen(false)}
          >
            <div>
              <div
                ref={ref}
                className={
                  'relative animate-showlight lightbox-scroll z-20' +
                  (isWithDes && (isShowSide || images[imageIndex]?.title)
                    ? ' max-h-80vh'
                    : ' ')
                }
              >
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={imageIndex}
                    timeout={500}
                    classNames="lightbox-fade"
                  >
                    <div
                      className={
                        'gap-0 transition-opacity duration-500 ' +
                        (load ? ' opacity-100' : 'opacity-0') +
                        (isWithDes && (isShowSide || images[imageIndex]?.title)
                          ? ' grid lg:grid-cols-lightbox '
                          : '')
                      }
                      {...handlers}
                    >
                      <GImage
                        useGallery={isWithDes}
                        path={
                          isUseTransfprmation
                            ? images[imageIndex]?.image[size]
                            : images[imageIndex]?.image
                        }
                        loadFn={(value: boolean) => {
                          setLoad(value);
                        }}
                        height="100%"
                        width="100%"
                        classes="self-center justify-self-center max-w-18.75rem sm:max-w-25rem  md:max-w-31.25rem lg:max-w-50vw lg:max-h-80vh m-auto block sm:inline-block "
                        transformation={
                          useProductTransform ? Producttransformation : ''
                        }
                        alt={images[imageIndex]?.title as string}
                      />
                      {showImageNumbers && (
                        <div className="flex justify-center items-center w-6.875rem h-2.5rem bg-dark-900 rounded-md text-sm text-light-600 font-semibold self-center absolute left-2.5 bottom-2.5 ">
                          {imageIndex + 1}{' '}
                          <i className="icon-arrow-right mx-3"></i>
                          {images.length}
                        </div>
                      )}

                      <div className=" flex flex-wrap flex-col justify-self-center h-full bg-light-600 w-full max-w-[300px] sm:max-w-25rem  md:max-w-31.25rem lg:max-w-none">
                        {isWithDes &&
                        (images[imageIndex]?.avatar ||
                          images[imageIndex]?.userName ||
                          images[imageIndex]?.location) ? (
                          <div className="grid grid-cols-profile p-5 border-b border-light-700 gap-x-2.5 text-base leading-5">
                            {images[imageIndex]?.avatar &&
                              images[imageIndex]?.avatar !== undefined && (
                                <div className="flex items-center">
                                  <GImage
                                    path={images[imageIndex]?.avatar as string}
                                    transformation="trip-cards"
                                    alt={images[imageIndex]?.tripName as string}
                                    width="100%"
                                    classes="rounded-full w-full "
                                    styles={{ height: 'auto !important' }}
                                  />
                                </div>
                              )}

                            <div>
                              <div className="text-dark-800 font-semibold">
                                {images[imageIndex]?.userName}
                              </div>
                              <div className="text-dark-700 ">
                                {' '}
                                {images[imageIndex]?.location}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {isWithDes && isDescHtml ? (
                          <div>
                            {images[imageIndex]?.title && (
                              <div
                                className={
                                  ' p-5 flex-grow-10 break-words text-dark-900 ' +
                                  styles.htmlDesc
                                }
                                dangerouslySetInnerHTML={{
                                  __html: images[imageIndex]?.title as string,
                                }}
                              ></div>
                            )}
                          </div>
                        ) : (
                          <div className="p-5 flex-grow-10 break-words">
                            {decodeTitle(images[imageIndex]?.title as string)}
                          </div>
                        )}

                        {isWithDes &&
                          isLink &&
                          (images[imageIndex]?.link ||
                            images[imageIndex]?.tripName) && (
                            <div className="p-5  border-t border-light-700 text-dark-800 font-semibold">
                              Experience this on a{' '}
                              {images[imageIndex]?.link ? (
                                <>
                                  <AppLink
                                    href={images[imageIndex]?.link as string}
                                    className="text-teal-900 cursor-pointer"
                                  >
                                    {images[imageIndex]?.tripName}
                                  </AppLink>{' '}
                                </>
                              ) : (
                                <span>{images[imageIndex]?.tripName}</span>
                              )}
                              trip
                            </div>
                          )}
                      </div>
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </CSSTransition>
        </div>
      </Modal>
    </>
  );
};
export default LightBox;

import {
  BannerTransformations,
  LightBoxTransformations,
  TTransformation,
} from '@/Lib/types/image-transformations';

import { ProductImageTransformations } from '@/Lib/types/image-transformations';
import { useWindowDimensions } from './useWindowDimensions';

function useTransformationBasedOnWindowSize(
  transformations?: TTransformation
): string {
  let { width } = useWindowDimensions();
  const appliedTransformations = transformations || BannerTransformations;

  width = width || 360;

  switch (true) {
    case width < 768:
      return appliedTransformations.SM;
    case width >= 768 && width < 1024:
      return appliedTransformations.MD;
    default:
      return appliedTransformations.LG;
  }
}

export const MobileBannerTransformation = BannerTransformations.SM;
export const MediumBannerTransformation = BannerTransformations.MD;
export const DesktopBannerTransformation = BannerTransformations.LG;

export const MobileProductImageTransformation = ProductImageTransformations.SM;
export const MediumProductImageTransformation = ProductImageTransformations.MD;
export const DesktopProductImageTransformation = ProductImageTransformations.LG;

export { useTransformationBasedOnWindowSize };

function useTransformationBasedOnWindowSizeLightBox(): string {
  let { width } = useWindowDimensions();
  const appliedTransformations = LightBoxTransformations;

  width = width || 360;

  switch (true) {
    case width < 680:
      return appliedTransformations.MD;
    case width >= 680:
      return appliedTransformations.LG;
    default:
      return appliedTransformations.LG;
  }
}

export { useTransformationBasedOnWindowSizeLightBox };
